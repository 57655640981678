import { BaseModel } from "./base.model";
import { ScheduleItem } from "./schedule-item.model"

export class Schedule extends BaseModel {
  constructor(
    public active: boolean,
    public title: string,
    public items?: []
  ) {
    super();
  }
}
                         